import { GatsbyImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'
import React, { useContext } from 'react'
import {SearchResultItemStyles} from '../../styles/search/SearchResultItemStyles'
import ParagraphText from '../typography/ParagraphText'
import { RegularTitle } from '../typography/Title'
import { SearchModalContext } from '../../context/searchModalContext'

function BlogsSearchResultItem({blog}){
    const {closeSearchModal} = useContext(SearchModalContext)
    return (
        <SearchResultItemStyles to={`/blogs/${blog.slug.current}`} onClick={closeSearchModal}>
            <GatsbyImage image={blog.coverImage.asset.gatsbyImageData} alt={blog.coverImage.alt} className='img'/>
            <div>
                <RegularTitle className="title">{blog.title}</RegularTitle>
                <ParagraphText className="categoriesText">
                    {format(new Date(blog.publishedAt), 'p, MMMM-dd-yyyy')}
                </ParagraphText>
            </div>
        </SearchResultItemStyles>
    )
}

function CategoriesSearchResultItem({category}){
    const {closeSearchModal} = useContext(SearchModalContext)
    return (
        <SearchResultItemStyles onClick={closeSearchModal} to={`/categories/${category.slug.current}`}>
            <RegularTitle className='title'>{category.title}</RegularTitle>
        </SearchResultItemStyles>
    )
}

function AuthorsSeachResultItem({author}){
    const {closeSearchModal} = useContext(SearchModalContext)
    return (
        <SearchResultItemStyles onClick={closeSearchModal} to={`/authors/${author.slug.current}`}>
            <GatsbyImage image={author.profileImage.asset.gatsbyImageData} alt={author.profileImage.alt} className='authorProfileImg'/>
            <div>
                <RegularTitle className="title">{author.name}</RegularTitle>
                {/* <ParagraphText className="categoriesText">
                    {format(new Date(blog.publishedAt), 'p, MMMM-dd-yyyy')}
                </ParagraphText> */}
            </div>
        </SearchResultItemStyles>
    )
}


export {BlogsSearchResultItem, CategoriesSearchResultItem, AuthorsSeachResultItem};