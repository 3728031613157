import React from 'react'
import {FaYoutube, FaTwitter, FaGithub, FaInstagram} from 'react-icons/fa'

export const socialLinks = [
    {
        name: "youtube",
        url: "https://www.youtube.com/c/Auctux",
        icon: <FaYoutube></FaYoutube>
    },
    {
        name: "twitter",
        url: "https://twitter.com/auctux",
        icon: <FaTwitter></FaTwitter>
    },
    {
        name: "github",
        url: "https://github.com/Josephbakulikira",
        icon: <FaGithub></FaGithub>
    },
    {
        name: "instagram",
        url: "https://www.instagram.com/auctux_com/",
        icon: <FaInstagram></FaInstagram>
    },
]