import React from 'react'
import HamburgerStyle from '../styles/HamburgerStyle'
function Hamburger({isOpen}) {
    return (
        <HamburgerStyle >
            <div className={`myHamburger ${isOpen ? 'is-active' : ''}`}>
                <span></span>
                <span>
                <span></span>
                </span>
                <span></span>
            </div>
        </HamburgerStyle>
    )
}

export default Hamburger
