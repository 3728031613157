import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  #mycanvas{
    position: absolute;
    
  }
  .starRating:hover{
    color: yellow !important;
    cursor: pointer !important;
  }

  :root{
    --primary: #21a3fA;
    --secondary: #753AFF;
    --darkBlue: #070747; 
    --darkPurple: #0E0034;
    --black-1: #0D121F;
    --black-2: #121826;
    --black-3: #121826aa;
    --white-1: #d5cfe2;
    --gray: #8c97aa;
    --grey: var(--gray);
    --white: white;
    --black: black;
  }
  html{
    font-size: 10px;
  }
  
  body{
    min-height: 100vh;
    line-height: 1.5;
    width: 100%;
    font-family: 'Inter';
    background: #0A111F;
    color: var(--white);
  }
  a{
    text-decoration: none;
    color: var(--white);
  }
  .container{
    max-width: 1200px;
    margin: 0 auto;
    width: 90%;
  }
  img, svg{
    height: 100%;
    width: 100%;
  }
  li, ul{
    list-style: none;
  }
  .gradient-container {
    background: linear-gradient(-45deg, #753AFF, #070747, #0E0034, #21a3fA);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: #07074722 0px 5px 15px;
  }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.gameContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  
}

.game_card{
  margin: 30px;
  border-radius: 20px;
  overflow: hidden;
  padding-bottom: 20px;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  background: linear-gradient(-45deg, #753AFF, #070747, #0E0034, #21a3fA);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.game__image{
  max-width: 600px;
  max-height: 300px;
  overflow: hidden;
  transition: all 0.5s;
  object-fit: cover;
}

.game_image_container{
  max-height: 300px;
  overflow: hidden;
  margin-bottom: 10px;

}

.game__image:hover{
  transform: scale(1.2);
  overflow: hidden;
}

.download-button{
  text-transform: capitalize;
  padding-inline: 25px;
  padding-block: 10px;
  background: #181818;
  border-radius: 30px;
  font-size: 15px;
  transition: all 0.5s;
}
.download-button:hover{
  background: black;
}

@keyframes rotate {
	 0% {
		 transform: rotate(0deg) scale(1);
     
	}
	 100% {
		 transform: rotate(360deg) scale(1.2);
	}
}
 .gradient {
  position: absolute;
	 --size: 250px;
	 --speed: 100s;
	 --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
   overflow-y: hidden !important;
	 width: var(--size);
	 height: var(--size);
	 filter: blur(calc(var(--size) / 5));
   /* color: #3011aa; */
	 background-image: linear-gradient(#37eba9, #5b37eb);
	 animation: rotate var(--speed) var(--easing) alternate infinite;
	 border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
   z-index: -99;
}
.gradient2 {
  position: absolute;
	 --size: 300px;
	 --speed: 50s;
   overflow-y: hidden !important;

	 --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
	 width: var(--size);
	 height: var(--size);
	 filter: blur(calc(var(--size) / 5));
   /* color: #10708b; */
	 background-image: linear-gradient(#10708b, #5b37eb);
	 animation: rotate var(--speed) var(--easing) alternate infinite;
	 border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
   z-index: -99;
}
.gradient3 {
  position: absolute;
	 --size: 150px;
	 --speed: 50s;
   overflow-y: hidden !important;

	 --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
	 width: var(--size);
	 height: var(--size);
	 filter: blur(calc(var(--size) / 5));
   /* color: #11aa71; */
	 background-image: linear-gradient(#2c109a, #11aa71);
	 animation: rotate var(--speed) var(--easing) alternate infinite;
	 border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
   z-index: -99;
}
*{
  transition: all 0.25s ease-out;
}
 @media (min-width: 720px) {
	 .gradient {
		 --size: 500px;
	}

  .starRating:hover{
    color: yellow !important;
    cursor: pointer !important;
  }

  .mainfeedback{
    /* @import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap");
    @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"); */


    .container {
      background: #f8faff;
      color: #4b537c;
      width: 100%;
      max-width: 450px;
      padding: 40px;
      border-radius: 15px;
      margin: 0 auto;
      margin-top: 50px;
      box-shadow: #dee8fe 0 10px 20px 5px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .heading {
      font-size: 18px;
    }

    .para {
      font-weight: 600;
    }

    .feedback-level {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .level {
      background: white;
      padding: 13px;
      border-radius: 7px;
      border: solid 0.5px #e0e9fe;
      cursor: pointer;
    }
    .level:hover {
      box-shadow: #fe73a5 0 2px 5px;
    }
    .level:hover i {
      color: #fe73a5;
    }
    .level i {
      font-size: 40px;
    }

    .feedback-msg textarea {
      margin-top: 5px;
      border: solid 2px #e0e9fe;
      padding: 13px;
      border-radius: 7px;
      outline: none;
      font-size: 14px;
      color: #4b537c;
      font-weight: 500;
      letter-spacing: 1px;
      width: 100%;
      height: 80px;
      resize: none;
    }

    .agreement .checkbox {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-bottom: 5px;
    }
    .agreement input {
      accent-color: #ff71a4;
    }
    .agreement a {
      color: #ff71a4;
      font-size: 12px;
      font-weight: 600;
    }
    .agreement label {
      font-size: 12px;
      font-weight: 600;
    }

    .buttons {
      display: flex;
      gap: 10px;
      justify-content: end;
    }
    .buttons a {
      background: white;
      color: #4b537c;
      width: 80px;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
    }
    .buttons a:hover {
      opacity: 0.9;
    }
    .buttons a:nth-child(1) {
      background: #fe71a4;
      color: #f8faff;
    }

  }
  .starRating:hover{
    color: yellow !important;
    cursor: pointer !important;
  }
}
 

`;

export default GlobalStyles;
