export const menu = [
    {
        title: 'Home',
        path: '/',
    },
    {
        title: 'Blogs',
        path: '/blogs',
    },
    {
        title: 'Categories',
        path: '/categories',
    },
    {
        title: 'Authors',
        path: '/authors',
    },
    
    {
        title: 'about',
        path: '/about'
    },
    {
        title: 'Contact',
        path: '/contact'
    },
]