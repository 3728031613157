import React from 'react'
import {useFlexSearch} from 'react-use-flexsearch'
import ParagraphText from '../typography/ParagraphText';
import { AuthorsSeachResultItem, BlogsSearchResultItem, CategoriesSearchResultItem } from './SearchResultItem';

function searchResult({searchQuery, blogsIndexStore, categoriesIndexStore, authorsIndexStore}) {
    
    const blogsResult = useFlexSearch(
        searchQuery,
        JSON.stringify(blogsIndexStore.index),
        blogsIndexStore.store
    );
    const categoriesResult = useFlexSearch(
        searchQuery,
        JSON.stringify(categoriesIndexStore.index),
        categoriesIndexStore.store
    );
    const authorsResult = useFlexSearch(
        searchQuery,
        JSON.stringify(authorsIndexStore.index),
        authorsIndexStore.store
    );

    if(blogsResult.length === 0 && categoriesResult.length === 0 && authorsResult.length === 0){
        return <ParagraphText>No Search Result 😥</ParagraphText>
    }
    
    return (
        <>
          {
            blogsResult.length > 0 && (
                <>
                <ParagraphText>Blogs</ParagraphText>
                {blogsResult.map((result)=> {
                    return <BlogsSearchResultItem key={result.id} blog={result} />
                })
                }
                </>
            )
          }
          {
            categoriesResult.length > 0 && (
                <>
                <ParagraphText>Categories</ParagraphText>
                {
                    categoriesResult.map((result) =>{
                        return <CategoriesSearchResultItem key={result.id} category={result} />
                    })
                }
                </>
            )
          }
          {
            authorsResult.length > 0 && (
                <>
                <ParagraphText>Authors</ParagraphText>
                {
                    authorsResult.map((result) =>{
                        return <AuthorsSeachResultItem key={result.id} author={result} />
                    })
                }
                </>
            )
          }
        </>
    )
}

export default searchResult
