import React from 'react'
import { SearchModalContextProvider } from '../context/searchModalContext'
import GlobalStyles from '../styles/GlobalStyles'
import Footer from './Footer'
import Header from './Header'
import Search from './search/SearchModal'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {VerticleButton as ScrollUpButton} from "react-scroll-up-button";

function Layout({children}) {
    return (
        <SearchModalContextProvider>
        <GlobalStyles/>
        <Search/>
        <ToastContainer/>
        <div >
            <ScrollUpButton style={{borderRadius: "30px", zIndex: "999999"}} />
        </div>
        <Header/>
           {children} 
        <Footer/>
        </SearchModalContextProvider>
    )
}

export default Layout
