import React from 'react'
import {SectionTitleStyles, TitleStyles} from '../../styles/typography/SectionTitleStyles'

function SectionTitle({children, tag, ...props}) {
    return (
        <SectionTitleStyles as={tag} {...props}>
            {children}
        </SectionTitleStyles>
    )
}
function RegularTitle({children, tag, ...props}) {
    return (
        <TitleStyles as={tag} {...props}>
            {children}
        </TitleStyles>
    )
}

export {SectionTitle, RegularTitle}
