import React, {useContext, useState} from 'react';
import { menu } from '../constants/menu';
import HeaderStyles from '../styles/HeaderStyles';
import {Link} from 'gatsby';
import {MdSearch} from 'react-icons/md'
import Logo from './Logo';
import ActionButton from './buttons/ActionButton';
import Hamburger from './Hamburger';
import { SearchModalContext } from '../context/searchModalContext';

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const {openSearchModal} = useContext(SearchModalContext);

    function handleNavItemClick(){
        if(isNavOpen){
            setIsNavOpen(false)
        }
    }
    function handleSearchModalOpen() {
        openSearchModal();  
    }
    return (
        <HeaderStyles>
            <div className='container'>
                <div className="header__container">
                <div className='logo'>
                    <Logo logoindex={0}/>
                </div>
                <div className={`nav__wrapper ${isNavOpen && 'open'}`}>
                    <div className="mobileIcon">
                        <div className="searchIcon">
                            <div className='searchIcon__wrapper' 
                            onClick={handleSearchModalOpen} 
                            onKeyDown={handleSearchModalOpen}
                            role="button"
                            tabIndex={0}
                            >
                                <MdSearch />
                            </div>
                        </div>
                        <ActionButton onClick={()=>setIsNavOpen(true)} onKeyDown={()=>setIsNavOpen(true)}>
                            <Hamburger isOpen={isNavOpen}/>
                        </ActionButton>
                    </div>
                    {
                        isNavOpen && (
                            <div className='mobileNavBg' aria-label='close menu' role='button' tabIndex={0} onKeyDown={()=>setIsNavOpen(false)} onClick={()=>setIsNavOpen(false)}></div>
                        )
                    }
                    <nav>
                        <ActionButton className='mobileMenuCloseBtn' onClick={()=>setIsNavOpen(false)} onKeyDown={()=>setIsNavOpen(false)}>
                            <Hamburger isOpen={isNavOpen}/>
                        </ActionButton>
                        <ul>
                            {menu.map(item => (
                                <li key={item.path}>
                                    <Link onClick={handleNavItemClick} to={item.path}>{item.title}</Link>
                                </li>
                            ))}
                            <li className='searchIcon'>
                                <div className='searchIcon__wrapper' 
                                    onClick={handleSearchModalOpen} 
                                    onKeyDown={handleSearchModalOpen}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <MdSearch />
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                </div>
                
            </div>
        </HeaderStyles>
    )
}

export default Header
