import React from 'react'
import ActionButtonStyles from '../../styles/buttons/ActionButtonStyles'
function ActionButton({children, ...rest}) {
    return (
        <div>
            <ActionButtonStyles role="button" tabIndex={0} {...rest}>
                {children}
            </ActionButtonStyles>
        </div>
    )
}

export default ActionButton
