import styled from 'styled-components';

export default styled.div`
  
    myHamburger{
        display: block;
    position: relative;
    z-index: 1;

    user-select: none;

    appearance: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    }
 

 span{
    display: block;
    width: 33px;
    height: 3px;
    margin-bottom: 8px;
    position: relative;

    background-color: white;
    border-radius: 6px;
    z-index: 100;
    transform-origin: 0 0;
    transition: 0.4s;
}

 span:nth-child(2){
    transform: translateX(-6px);
}
 span span{
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 33px;
    top: -4px;
    border: 2px solid var(--primary);
    background-color: transparent;
}

:hover span:nth-child(2){
    transform: translateX(6px);
}
:hover span:nth-child(3){
    /* transform: translateX(-6px); */
}
.is-active{
    transition: 0.4s;
}
.is-active span:nth-child(1){
    transform: translate(0px, -2px) rotate(45deg);
}
.is-active span:nth-child(3){
    transform: translate(0px, 0px) rotate(-45deg);
}
.is-active span:nth-child(2){
    opacity: 0;
    transform: translateX(15px);
}

.is-active:hover {
    /* transform-origin:center; */
    transform: scale(1.1);
}

.is-active:hover span{
    background-color:#03BB62;
}

`;