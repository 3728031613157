import React from 'react'
import AuctuxLogo from '../images/auctux.png';
import AuctuxLogo1 from '../images/1.png';
import AuctuxLogo2 from '../images/2.png';
import AuctuxLogo3 from '../images/3.png';

import LogoStyles from '../styles/LogoStyles';

function Logo({logoindex}) {
    return (
        <LogoStyles to='/'>
            {(logoindex == 0||logoindex==undefined) && <img src={AuctuxLogo}/>}
            {(logoindex == 1) && <img style={{width: "200px", marginBottom: "20px"}} src={AuctuxLogo1}/>}
            {(logoindex == 2) && <img src={AuctuxLogo2}/>}
            {(logoindex == 3) && <img src={AuctuxLogo3}/>}


        </LogoStyles>
    )
}

export default Logo
